<template>
	<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
		<a-form layout="vertical" :form="form" @submit="handleSubmit">
			<div class="row">
				<div class="col-md-3">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.taxRegime')">
						<a-select showSearch option-filter-prop="children" :filter-option="filterOption" v-decorator="[
								'tax_regime_id',
								{
									rules: requiredFileds
								},
							]">
							<a-select-option v-for="(element, index) in taxRegimesList" :key="index" :value="element.id">{{ element.code }} - {{ element.description }}</a-select-option>
						</a-select>
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.recordTyupe')">
						<a-select v-decorator="[
								'legal_type',
								{
									rules: requiredFileds
								},
							]">
							<a-select-option value="f">{{ $t('suppliersModule.edit.taxInfo.physicalPerson') }}</a-select-option>
							<a-select-option value="m">{{ $t('suppliersModule.edit.taxInfo.moralPerson') }}</a-select-option>
						</a-select>
					</a-form-item>
				</div>
				<div class="col-md-6">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.businessName')">
						<a-input v-decorator="[
								'business_name',
								{
									rules: requiredFileds
								},
							]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.rfc')">
						<a-input class="text-uppercase" v-decorator="[
								'rfc',
								{
									rules: requiredFileds
								},
							]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.street')">
						<a-input v-decorator="['street_name']" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.externalNumber')">
						<a-input v-decorator="['outdoor_number']" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.internalNumber')">
						<a-input v-decorator="['interior_number']" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.cp')">
						<a-input v-decorator="['postal_code', { rules: requiredFileds }]" v-mask="'#####'" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.neighborhood')">
						<a-input v-decorator="['suburb']" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.municipality')">
						<a-input v-decorator="['municipality']" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-6">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.state')">
						<a-input v-decorator="['state']" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-6">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.country')">
						<a-input v-decorator="['country']" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-6">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.contactPhone')">
						<a-input v-decorator="[
								'phone',
								{
									rules: requiredFileds
								},
							]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-6">
					<a-form-item :label="$t('suppliersModule.edit.taxInfo.notificationEmail')">
						<a-input v-decorator="[
								'email',
								{
									rules: emailField
								},
							]" autocomplete="off" />
					</a-form-item>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4 text-left">
					<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">{{ $t('general.back') }}</a-button>
				</div>
				<div class="col-md-8 text-right">
					<a-button icon="save" class="btn btn-success" htmlType="submit">{{ $t('general.save') }}</a-button>
				</div>
			</div>
		</a-form>
	</a-spin>
</template>
<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import { mask } from 'vue-the-mask'

export default {
	name: 'customerTaxComponent',
	directives: {
		mask,
	},
	computed: {
		...mapGetters('customers', ['spinnerLoader', 'spinnerLoaderLabel', 'actualRecord']),
		...mapGetters('taxRegimes', ['taxRegimesList']),
		isNewRecord() {
			return !utilities.objectValidate(this.actualRecord, 'tax.id', false)
		},
		requiredFileds() {
			let rules = [{
				required: true,
				message: this.$t('general.requiredField'),
			}]
			return rules
		},
		emailField() {
			let rules = [{
				required: true,
				message: this.$t('general.requiredField'),
			},
			{
				email: true,
				message: this.$t('general.validEmail'),
			}]
			return rules
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
		}
	},
	async beforeMount() {
		if (!this.taxRegimesList.length) {
			this.$store.dispatch('taxRegimes/GET')
		}
	},
	mounted() {
		if (utilities.objectValidate(this.actualRecord, 'tax.id', false)) {
			this.setLocalData(this.actualRecord)
		}
	},
	methods: {
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		onCancel() {
			this.$router.replace('/customers')
		},
		setLocalData(newValue) {
			this.form.setFieldsValue({
				tax_regime_id: utilities.objectValidate(newValue, 'tax.tax_regime_id', ''),
				legal_type: utilities.objectValidate(newValue, 'tax.legal_type', ''),
				business_name: utilities.objectValidate(newValue, 'tax.business_name', ''),
				rfc: utilities.objectValidate(newValue, 'tax.rfc', ''),
				street_name: utilities.objectValidate(newValue, 'tax.street_name', ''),
				outdoor_number: utilities.objectValidate(newValue, 'tax.outdoor_number', ''),
				interior_number: utilities.objectValidate(newValue, 'tax.interior_number', ''),
				postal_code: utilities.objectValidate(newValue, 'tax.postal_code', ''),
				suburb: utilities.objectValidate(newValue, 'tax.suburb', ''),
				municipality: utilities.objectValidate(newValue, 'tax.municipality', ''),
				state: utilities.objectValidate(newValue, 'tax.state', ''),
				country: utilities.objectValidate(newValue, 'tax.country', ''),
				phone: utilities.objectValidate(newValue, 'tax.phone', ''),
				email: utilities.objectValidate(newValue, 'tax.email', ''),
			})
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					//
					let payload = {
						...values,
						section: 'customers/tax-receipt',
						rfc: values.rfc.toUpperCase(),
						customer_id: this.actualRecord.general.id,
					}
					if (this.isNewRecord) {
						this.$store.dispatch('customers/CREATE', { payload })
					} else {
						payload.id = this.actualRecord.tax.id
						this.$store.dispatch('customers/UPDATE', payload)
					}
				}
			})
		},
	},
	watch: {
		actualRecord: {
			deep: true,
			handler(newValue) {
				if (utilities.objectValidate(newValue, 'tax.id', false)) {
					this.setLocalData(newValue)
				}
			},
		},
	},
}
</script>
